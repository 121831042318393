import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IotDevicesRoutingModule } from './iot-devices-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { IotDevicesComponent } from './iot-devices/iot-devices.component';
import { IotCreateDevicesComponent } from './iot-create-devices/iot-create-devices.component';
import { SharedModule } from '../../shared/shared.module';
import { ComponentsModule } from '../../shared/components/components.module';
import { UiSwitchModule } from 'ngx-ui-switch';
@NgModule({
  declarations: [IotDevicesComponent, IotCreateDevicesComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    IotDevicesRoutingModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    AccordionModule,
    SharedModule,
    ComponentsModule,
    UiSwitchModule,
  ],
  // providers: [
  //   {
  //   provide: DROPZONE_CONFIG,
  //   useValue: DEFAULT_DROPZONE_CONFIG
  // }]
})
export class IotDevicesModule {}
