<!-- <app-rp-loader></app-rp-loader> -->
<!-- <div bsModal tabindex="-1">
    <div class="modal-dialog modal-dialog-centered"> -->
        <div class="modal-content">
            <div class="modal-header bg-primary text-white">
                <h5 class="modal-title">
                    {{ hasEmptyPassword ? 'Create Password' : 'Change Password' }}
                  </h5>
                  
                <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeModal()"></button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" [formGroup]="changePwdForm" >
                    <div class="mb-3" *ngIf="!hasEmptyPassword">
                        <label>Enter Old Password <span class="required">*</span></label>
                        <div class="input-group auth-pass-inputgroup">
                            <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                formControlName="oldPassword" class="form-control" id="oldPassword"
                                placeholder="Old Password" />
                            <button class="btn btn-light ms-0" type="button" id="password-addon">
                                <i class="mdi mdi-eye-outline"
                                    [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"
                                    (click)="toggleFieldTextType()"></i></button>
        
                            @if((submitted || f['oldPassword'].touched) && f['oldPassword'].errors){
                            <div class="invalid-feedback">
                                @if(f['oldPassword'].errors['required']){
                                <div>{{changePWdValidations.oldPassword.required}}</div>}
                                @if(f['oldPassword'].errors['minlength']) {
                                <div>{{changePWdValidations.oldPassword.minLengthValidator}}</div>}
                                @if(f['oldPassword'].errors['maxlength']) {
                                <div>{{changePWdValidations.oldPassword.MaxLengthValidator}}</div>}
                            </div>}
                        </div>
                    </div>
        
                    <div class="mb-3">
                        <label>Enter New Password <span class="required">*</span></label>
                        <div class="input-group auth-pass-inputgroup">
                            <input [type]="newPwdfieldTextType ? 'text' : 'password'" class="form-control"
                                formControlName="newPassword" id="newPassword" placeholder="New Password"
                                (input)="onPasswordInput()" />
        
                            <!-- <button class="btn btn-light ms-0" type="button" (click)="togglePasswordRequirements()" aria-label="Password requirements">
                                    <i class="mdi mdi-lock-outline"></i>
                                  </button> -->
        
                            <button class="btn btn-light ms-0" type="button" id="password-addon">
                                <i class="mdi mdi-eye-outline"
                                    [ngClass]="{'mdi-eye-off-outline': !newPwdfieldTextType, 'mdi-eye-outline': newPwdfieldTextType}"
                                    (click)="toggleNewPwdFieldTextType()"></i>
                            </button>
                        </div>
        
                        <!-- Password Requirement List -->
                        <!-- Password Requirement List -->
                        @if(submitted && f['newPassword'].errors){
                        <div class="invalid-feedback">
                            @if(f['newPassword'].errors['required']){
                            <div>{{changePWdValidations.newPassword.required}}</div>}
                            @if(f['newPassword'].errors['pattern']) {
                            <div>Invalid Formate</div>}
                        </div>}
                        <div class="password-requirements-popup" *ngIf="showPasswordRequirements">
                            <ul class="password-requirements">
                                <li [class.valid]="hasMinLength(changePwdForm.get('newPassword')?.value || '')">
                                    At least {{ policy.MinLength }} characters
                                </li>
                                <li *ngIf="policy.RequiresUpperCase"
                                    [class.valid]="hasUppercaseLetter(changePwdForm.get('newPassword')?.value || '')">
                                    At least 1 uppercase letter
                                </li>
                                <li *ngIf="policy.RequiresLowerCase"
                                    [class.valid]="hasLowercaseLetter(changePwdForm.get('newPassword')?.value || '')">
                                    At least 1 lowercase letter
                                </li>
                                <li *ngIf="policy.RequiresSpecialChar"
                                    [class.valid]="hasSpecialCharacter(changePwdForm.get('newPassword')?.value || '')">
                                    Special character ({{ policy.SpecialCharacters }})
                                </li>
                                <li *ngIf="policy.RequiresNumber"
                                    [class.valid]="hasNumber(changePwdForm.get('newPassword')?.value || '')">
                                    At least 1 number
                                </li>
                            </ul>
                        </div>
        
                    </div>
        
                    <div class="mb-3">
                        <label>Re-enter New Password <span class="required">*</span></label>
                        <div class="input-group auth-pass-inputgroup">
                            <input [type]="oldPwdfieldTextType ? 'text' : 'password'" class="form-control"
                                formControlName="confirmPassword" class="form-control" id="confirmPassword"
                                placeholder="Confirm Password" />
                            <button class="btn btn-light ms-0" type="button" id="password-addon">
                                <i class="mdi mdi-eye-outline"
                                    [ngClass]="{'mdi-eye-off-outline': !oldPwdfieldTextType, 'mdi-eye-outline': oldPwdfieldTextType}"
                                    (click)="toggleConfirmPwdFieldTextType()"></i></button>
                            @if(submitted && f['confirmPassword'].errors){
                            <div class="invalid-feedback">
                                @if(f['confirmPassword'].errors['required']){
                                <span>{{changePWdValidations.confirmPassword.required}}</span>
                                }
                                @if(f['confirmPassword'].errors['pattern']) {
                                <div>{{regexValidationMsz}}</div>}
                                @if(f['confirmPassword'].errors['minlength']) {
                                <div>{{changePWdValidations.confirmPassword.minLengthValidator}}</div>}
                                @if(f['confirmPassword'].errors['maxlength']) {
                                <div>{{changePWdValidations.confirmPassword.MaxLengthValidator}}</div>}
        
        
                            </div>}
        
                            <div class="invalid-feedback">
                                <div *ngIf="f['confirmPassword'].value != f['newPassword'].value">
                                    {{changePWdValidations.match_required_validations}}
                                </div>
                                <div
                                    *ngIf="(f['oldPassword'].value && f['newPassword'].value) && f['oldPassword'].value == f['newPassword'].value">
                                    {{changePWdValidations.oldPwdandNewPwd_validations}}
                                </div>
        
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="alert alert-danger text-center mb-4 p-2 mt-3" *ngIf="otpErrorMsg" role="alert">
                {{respMessage}}
            </div>
            <div class="alert alert-success text-center mb-4 p-2 mt-3" *ngIf="otpSuccessMsg" role="alert">
                {{respMessage}}
            </div>
            <div class="modal-footer">
                <!-- <div class="alert alert-danger text-center mb-4 p-2 mt-3" *ngIf="otpErrorMsg" role="alert">
                            {{respMessage}}
                        </div> -->
                <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
                <button  *ngIf="!hasEmptyPassword" type="button" class="btn btn-primary waves-effect waves-light" (click)="updatePwd()"
                    [disabled]="!changePwdForm.valid">Update</button>
                    <button *ngIf="hasEmptyPassword" type="button" class="btn btn-primary waves-effect waves-light" (click)="CreatePwd()"
                    [disabled]="!changePwdForm.valid">Create</button>
            </div>
        </div><!-- /.modal -->
        <!-- </div>
                </div> -->