<app-rp-loader></app-rp-loader>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <!-- <h4 class="mb-0 font-size-18">electricStatuss And eb_dg_statuss</h4> -->
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <!-- <div class="page-title-right">
              <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item active"><a href="javascript: void(0);">Home</a>
                  </li>
                  
                  <li class="breadcrumb-item">Create New
                  </li>
              
              </ol>
            </div> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title mb-4"></h4> -->
          <div id="basic-example">
            <ng-stepper #cdkStepper [linear]="true" class="wizard custom-steeper sm-stepper">

              <cdk-step [optional]="false">
                <ng-template cdkStepLabel>
                  <span class="number">1.</span>
                  <span>EB / DG Shift Status</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 0){

                <section class="">

                  <div class="row mb-2 d-sm-block d-md-none">
                    <h4 class="card-title mb-4 text-decoration-underline">electricStatuss</h4>
                  </div>
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="electricStatusgridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                      [groupable]="false" [selectable]="selectableSettings" [pageable]="{
                                        buttonCount: buttonCount,
                                        info: info,
                                        type: type,
                                        pageSizes: pageSizes,
                                        previousNext: previousNext
                                      }" [reorderable]="true" filterable="menu" [filter]="electricStatusstate.filter"
                      [sort]="electricStatusstate.sort" [columnMenu]="{ filter: true }" [resizable]="true"
                      kendoGridSelectBy="id" scrollable="scrollable" (filterChange)="onFilterChange($event)"
                      (sortChange)="onSortChange($event,'electricStatus')"
                      (pageChange)="electricStatusPageChange($event)" (selectionChange)="selectionChanged($event)"
                      (dataStateChange)="dataStateChange($event)">
                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="searchedelectricStatusValue"
                          (valueChange)="onelectricStatusFilter($event)"></kendo-textbox> -->

                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [(ngModel)]="searchedelectricStatusValue" [clearButton]="true"
                          (valueChange)="clearSearchValueChangeEbDgData($event)"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none"
                            (click)="onelectricStatusFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>

                        <div class="sm-device d-flex justify-content-between">

                          <button kendoGridExcelCommand class="btn btn-light" (click)="exportelectricStatus('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>

                      </ng-template>
                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="deviceSRNO" title="Device Sr. No"></kendo-grid-column>
                      <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                      <kendo-grid-column field="ebDgShiftStatus" title="EbDgShiftStatus"></kendo-grid-column>
                      <kendo-grid-column field="ebDgShiftValue" title="EbDgShiftValue"></kendo-grid-column>
                      <kendo-grid-column  field="date" title="Date & time" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.date | date: 'dd MMM yyyy hh:mm a' }}
                        </ng-template>
                      </kendo-grid-column>

                    </kendo-grid>
                  </div>
                  <!--electricStatus download buttons start-->

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectionelectricStatuss.length>0 ? mySelectionelectricStatuss:allelectricStatussList.length>0?allelectricStatussList:electricStatusgridData.data"
                      #electricStatusGrid>
                      <kendo-grid-column field="devicesSRNO" title="Device Sr. No"></kendo-grid-column>
                      <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                      <kendo-grid-column field="ebDgShiftStatus" title="EbDgShiftStatus"></kendo-grid-column>
                      <kendo-grid-column field="ebDgShiftValue" title="EbDgShiftValue"></kendo-grid-column>
                      <kendo-grid-column field="date" title="date" filter="date"></kendo-grid-column>
                      <kendo-grid-command-column title="Actions" [columnMenu]="false"
                       ></kendo-grid-command-column>
                      <kendo-grid-excel fileName="EB_DG_Shift_Status.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>
                  <!--electricStatus download buttons end-->


                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                    <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                        cdkStepperNext>Next</button></li>
                  </ul>

                </section>


                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">2.</span>
                  <span>Electricity On / Off history</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 1){
                <section>

                  <div class="row mb-2 d-sm-block d-md-none">
                    <h4 class="card-title mb-4 text-decoration-underline">eb_dg_statuss</h4>
                  </div>

                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">

                    <kendo-grid [data]="eb_dg_statussgridData" [pageSize]="eb_dg_statusPageSize"
                      [skip]="eb_dg_statusskip" [sortable]="true" [pageable]="{
                    buttonCount: buttonCount,
                    info: info,
                    type: type,
                    pageSizes: pageSizes,
                    previousNext: previousNext
                  }" [reorderable]="true" [selectable]="selectableSettings" filterable="menu"
                      [filter]="eb_dg_statusstate.filter" [sort]="eb_dg_statusstate.sort"
                      [columnMenu]="{ filter: true }" [resizable]="true" kendoGridSelectBy="id" scrollable="scrollable"
                      (pageChange)="eb_dg_statusPageChange($event)"
                      (dataStateChange)="eb_dg_statusdataStateChange($event)"
                      (selectionChange)="eb_dg_statusselectionChanged($event)"
                      (filterChange)="oneb_dg_statusFilterChange($event)"
                      (sortChange)="onSortChange($event,'eb_dg_status')">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="searchedeb_dg_statusValue"
                          (valueChange)="oneb_dg_statusFilter($event)"></kendo-textbox> -->
                        <div class="search_icon"> 
                          <kendo-textbox [style.width.px]="250" [(ngModel)]="searchedeb_dg_statusValue" [clearButton]="true"
                          (valueChange)="clearSearchValueChangeOnOffData($event)"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none"
                            (click)="oneb_dg_statusFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device">

                          <!-- <button kendoGridPDFCommand class="btn btn-light me-2" (click)="exporteb_dg_status('Pdf')">
                            <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->
                          <button kendoGridExcelCommand class="btn btn-light" (click)="exporteb_dg_status('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>
                      </ng-template>
                   
                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                        <kendo-grid-column field="deviceSRNO" title="Device Sr. No"></kendo-grid-column>
                        <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                      <kendo-grid-column field="date" title="Date & time" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.date | date: 'dd MMM yyyy hh:mm a' }}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="status" title="status"></kendo-grid-column>


                    </kendo-grid>
                  </div>
                  <!--eb_dg_status download buttons start-->
                  <!-- <kendo-excelexport [data]="mySelectioneb_dg_status.length>0 ? mySelectioneb_dg_status:eb_dg_statussgridData.data"
                    fileName="eb_dg_status.xlsx" #eb_dg_statusexcelexport>
                    <kendo-excelexport-column field="electricStatus.name" title="Type"></kendo-excelexport-column>
                    <kendo-excelexport-column field="name" title="Name"></kendo-excelexport-column>
                  </kendo-excelexport> -->
                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectioneb_dg_status.length>0 ? mySelectioneb_dg_status:alleb_dg_statusList.length>0?alleb_dg_statusList:eb_dg_statussgridData.data"
                      #eb_dg_statusGrid>
                      <kendo-grid-column field="deviceSRNO" title="Device Sr. No"></kendo-grid-column>
                      <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                      <kendo-grid-column field="electricStatus.name" title="Type"></kendo-grid-column>
                      <kendo-grid-column field="name" title="Name"></kendo-grid-column>
                      <kendo-grid-pdf fileName="eb_dg_status.pdf" [allPages]="true" paperSize="A4"
                        [landscape]="true"></kendo-grid-pdf>
                      <kendo-grid-excel fileName="electricStatuss.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>
                  <!--eb_dg_status download buttons end-->

                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                  </ul>

                </section>
                }
              </cdk-step>

            </ng-stepper>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>