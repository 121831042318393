import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IotDevicesComponent } from './iot-devices/iot-devices.component';
import { IotCreateDevicesComponent } from './iot-create-devices/iot-create-devices.component';

const routes: Routes = [
  { path: 'iotDevices', component: IotDevicesComponent },
  { path: 'iotDevices/createDevices', component: IotCreateDevicesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IotDevicesRoutingModule {}
