import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class PdfexportService {
  constructor() { }

  private chunkArray(myArray: any[], chunk_size: number): any[][] {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }
  downloadExcel(data: any[], fileName: string): void {
    const chunkSize = 100000; //chunk size
    const chunks = this.chunkArray(data, chunkSize);

    chunks.forEach((chunk, index) => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(chunk);

      const workbook: XLSX.WorkBook = {
        Sheets: { [`data${index}`]: worksheet },
        SheetNames: [`data${index}`],
      };

      // Generate a buffer
      const excelBuffer: any = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      this.saveAsExcelFile(excelBuffer, `${fileName}`);
    });
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}.xlsx`);
  }










}
