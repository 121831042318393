<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title mb-4">Project Details</h4> -->
          <div id="basic-example">


            <section class="p-2">
              <form>
                <!-- <div class="row mb-2 d-sm-block">
                  <h4 class="card-title mb-4 text-decoration-underline">Project Details</h4>
                </div> -->
                <div class="row">
                  <div class="col-lg-3" *ngIf="viewDetails?.projectName">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">Project Name</label>
                      <p>{{ viewDetails?.projectName }}</p>

                    </div>
                  </div>

                  <div class="col-lg-3" *ngIf="viewDetails?.deviceSRNO">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">Device Sr. No</label>
                      <p>{{ viewDetails?.deviceSRNO }}</p>
                    </div>
                  </div>

                  
                  <div class="col-lg-3" *ngIf="viewDetails?.macAddress">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">Mac Address</label>
                      <p>{{ viewDetails?.macAddress }}</p>
                    </div>
                  </div>


                  <div class="col-lg-3" *ngIf="viewDetails?.utilityType">
                    <div class="mb-4">
                      <label for="">Utility Type</label>
                      <p>{{ viewDetails?.utilityType }}</p>
                    </div>
                  </div>
                  <!-- <div class="col-lg-3" *ngIf="viewDetails?.onOffStatus">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">On/Off Status</label>
                      <p>{{ viewDetails?.onOffStatus }}</p>
                    </div>
                  </div> -->
                  <div class="col-lg-3" *ngIf="viewDetails?.meterReading">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">Meter Reading</label>
                      <p>{{ viewDetails?.meterReading }}</p>
                    </div>
                  </div>
                  <div class="col-lg-3" *ngIf="viewDetails?.meterReadingDG !=null">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">Meter ReadingDG</label>
                      <p>{{ viewDetails?.meterReadingDG }}</p>
                    </div>
                  </div>
                  <div class="col-lg-3" *ngIf="viewDetails?.meterReadingEB != null">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">Meter ReadingEB</label>
                      <p>{{ viewDetails?.meterReadingEB }}</p>
                    </div>
                  </div>

                  <div class="col-lg-3" *ngIf="viewDetails?.onOffStatus != null">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">Meter On/Off</label>
                      <p>{{ viewDetails?.onOffStatus }}</p>
                    </div>
                  </div>


                  <!-- <div class="col-lg-3" *ngIf="viewDetails?.devicesSRNO">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">
                        {{ type === 'Reading Details' ? 'Devices SR NO' : 'Device Address' }}
                      </label>
                      <p>{{ viewDetails?.devicesSRNO }}</p>
                    </div>
                  </div> -->

                  <div class="col-lg-3" *ngIf="viewDetails?.date_Time">
                    <div class="mb-4">
                      <label for="basicpill-lastname-input">Date</label>
                      <p>{{ viewDetails?.date_Time | date: 'dd/MM/yyyy hh:mm a' }}</p>
                    </div>
                  </div>
                  
             

                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-6" *ngIf="viewDetails?.rawJson">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">Raw JSON</label>
                          <div
                            style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; padding: 10px; background-color: #f9f9f9;">
                            <pre>{{ viewDetails?.rawJson | json }}</pre>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6" *ngIf="viewDetails?.convertedJson">
                        <div class="mb-4">
                          <label for="basicpill-lastname-input">{{ this.type =='Reading Details'?'Converted JSON':'Raw Json'}}</label>
                          <div
                            style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; padding: 10px; background-color: #f9f9f9;">
                            <pre>{{ viewDetails?.convertedJson | json }}</pre>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </form>
            </section>

           
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
  </div>
</div>