import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersModule } from './users/users.module';
import { FeaturesRoutingModule } from './features-routing.module';
import { RpDashboardComponent } from './rp-dashboard/rp-dashboard.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';

import { MastersModule } from './masters/masters.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { IotDevicesModule } from './devices/iot-devices.module';
import { IotReadingModule } from './readings/iot-reading.module';
import { ElectricStatusModule } from './iot-electricity-status/electric_status.module';

@NgModule({
  declarations: [RpDashboardComponent],
  imports: [
    CommonModule,
    MastersModule,
    UsersModule,
    ConfigurationModule,
    IotDevicesModule,
    IotReadingModule,
    NgSelectModule,
    ElectricStatusModule,
    BsDatepickerModule,
    BsDatepickerModule,
    TimepickerModule,
    BsDropdownModule,
    NgStepperModule,
    CdkStepperModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule.forRoot(),
    FeaturesRoutingModule,
  ],
})
export class FeaturesModule {}
