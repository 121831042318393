import { createAction, props } from '@ngrx/store';
import { readings } from './iot-readings.models';

// readings creation
export const getreadings = createAction(
  '[device] getreadings',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getreadingssSuccess = createAction(
  '[device] getreadings Success',
  props<{ getreadingsResponse: any }>()
);
export const getreadingssFailure = createAction(
  '[device] getreadings Failure',
  props<{ getreadingsError: any }>()
);

export const getAllreadings = createAction(
  '[device] getAllreadings',
  props<{
    pageIndex: number;
    pageSize: number|undefined;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllreadingssSuccess = createAction(
  '[device] getAllreadings Success',
  props<{ getAllreadingsResponse: any }>()
);
export const getAllreadingssFailure = createAction(
  '[device] getAllreadings Failure',
  props<{ getAllreadingsError: any }>()
);

// getrawdata
export const GetrawData = createAction(
  '[device] GetrawData',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getrawDataSuccess = createAction(
  '[device] getrawData Success',
  props<{ getrawDataSuccessResponse: any }>()
);
export const getrawDataFailure = createAction(
  '[device] getrawData Failure',
  props<{ getrawDataeError: any }>()
);

// getAllrawdata
export const GetAllrawData = createAction(
  '[device] GetAllrawData',
  props<{
    pageIndex: number;
    pageSize: number|undefined;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const GetAllrawDataSuccess = createAction(
  '[device] GetAllrawData Success',
  props<{ GetAllrawDataSuccessResponse: any }>()
);
export const GetAllrawDataFailure = createAction(
  '[device] GetAllrawData Failure',
  props<{ GetAllrawDataeError: any }>()
);

// getrawdata
export const getArchivedData = createAction(
  '[device] getArchived',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getArchivedDataSuccess = createAction(
  '[device] getArchived Success',
  props<{ getArchivedResponse: any }>()
);
export const getArchivedDataFailure = createAction(
  '[device] getArchived Failure',
  props<{ getArchivedeError: any }>()
);

//all archivedData
export const getAllArchivedData = createAction(
  '[device] getAllArchived',
  props<{
    pageIndex: number;
    pageSize: number|undefined;
    searchTerm?: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllArchivedDataSuccess = createAction(
  '[device] getAllArchived Success',
  props<{ getAllArchivedResponse: any }>()
);
export const getAllArchivedDataFailure = createAction(
  '[device] getAllArchived Failure',
  props<{ getAllArchivedeError: any }>()
);

// reset store data
export const resetreadingsState = createAction(
  '[resetreadingsState ] Reset State'
);

//electric status

export const getElectricStatus = createAction(
  '[getElectricStatus] getElectricStatus',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getElectricStatussSuccess = createAction(
  '[getElectricStatus] getElectricStatus Success',
  props<{ getElectricStatusResponse: any }>()
);
export const getElectricStatusFailure = createAction(
  '[getElectricStatus] getElectricStatus Failure',
  props<{ getElectricStatusError: any }>()
);

export const getAllElectricStatus = createAction(
  '[getAllElectricStatus] getAllElectricStatus',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllElectricStatussSuccess = createAction(
  '[getAllElectricStatus] getAllElectricStatus Success',
  props<{ getAllElectricStatusResponse: any }>()
);
export const getAllElectricStatusFailure = createAction(
  '[getAllElectricStatus] getAllElectricStatus Failure',
  props<{ getAllElectricStatusError: any }>()
);

export const getEb_Dg_Status = createAction(
  '[] getEb_Dg_Status',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getEb_Dg_StatusSuccess = createAction(
  '[] getEb_Dg_Status Success',
  props<{ getEb_Dg_StatusResponse: any }>()
);
export const getEb_Dg_StatusFailure = createAction(
  '[] getEb_Dg_Status Failure',
  props<{ getEb_Dg_StatusError: any }>()
);

export const getAllEb_Dg_Status = createAction(
  '[] getAllEb_Dg_Status',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllEb_Dg_StatusSuccess = createAction(
  '[] getAllEb_Dg_Status Success',
  props<{ getAllEb_Dg_StatusResponse: any }>()
);
export const getAllEb_Dg_StatusFailure = createAction(
  '[] getAllEb_Dg_Status Failure',
  props<{ getAllEb_Dg_StatusError: any }>()
);

export const resetExportedData = createAction(
  '[AllReadingsExport] resetExportedData'
);
