import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, HostListener, ElementRef, AfterViewInit, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ChangePwdModal } from '../../../shared/modals/common';
import { Validations } from '../../../shared/constants/validations';
import { SharedService } from '../../../shared/shared.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';

@Component({
  selector: 'app-rp-changepassword',
  templateUrl: './rp-changepassword.component.html',
  styleUrl: './rp-changepassword.component.scss',
})
export class RpChangepasswordComponent {
  changePwdForm!: UntypedFormGroup;
  newpasswordForm!: UntypedFormGroup;
  submitted: any = false;
  newpasswordsubmitted: any = false;
  error: any = '';
  success: any = '';
  loading: any = false;
  @Input() IsotpPassword: boolean = false;
  otpSuccessMsg: boolean = false;
  otpErrorMsg: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();
  fieldTextType!: boolean;
  newPwdfieldTextType!: boolean;
  oldPwdfieldTextType!: boolean;
  mailId: any;
  respMessage: any;
  changePWdValidations = Validations.ChangePwdPage;
  policy: any;
  currentUserData: any;
  hasEmptyPassword:boolean=true;
  // tslint:disable-next-line: max-line-length
  constructor(
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private sharedService: SharedService,    
    private router: Router,
    private modalService: BsModalService,
    private elementRef: ElementRef
  ) { 
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.hasEmptyPassword =  this.currentUserData?.hasEmptyPassword;

  }

  reset() { }
  pwdRegExp: any;
  regexValidationMsz: any

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (this.showPasswordRequirements && this.elementRef.nativeElement.contains(targetElement)) {
      this.hidePasswordRequirements();
    }
  }

  //   @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent): void {
  //   const targetElement = event.target as HTMLElement;
  //   if (!this.clickedInside && this.showPasswordRequirements && !this.elementRef.nativeElement.contains(targetElement)) {
  //     this.hidePasswordRequirements();
  //   }

  // }

  getPwdRegExp() {
    this.loaderService.setLoading(true);
    this.authService.getPwdRegExp().subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res.statusCode === 200 && res.data.length > 0) {
        const policyData = res.data[0]; // Assuming the first item in the array is the policy
        this.policy = {
          MinLength: policyData.minLength,
          MaxLength: policyData.maxLength,
          RequiresUpperCase: policyData.requiresUpperCase,
          RequiresLowerCase: policyData.requiresLowerCase,
          RequiresSpecialChar: policyData.requiresSpecialChar,
          RequiresNumber: policyData.requiresNumber,
          SpecialCharacters: policyData.specialCharacters
        };
        this.updateFormWithRegexValidator(); // Update form once the regex is fetched
      } else {
        console.log('Failed to retrieve password policy:', res);
      }
    }, error => {
      this.loaderService.setLoading(false);
      console.log('Error fetching password regex:', error);
    });
  }



  showPasswordRequirements = false;

  onPasswordInput(): void {
    this.showPasswordRequirements = true;
  }

  hidePasswordRequirements(): void {
    this.showPasswordRequirements = false;
  }


  updateFormWithRegexValidator() {
    const validators = [
      Validators.required,
      Validators.minLength(this.policy.MinLength),
      Validators.maxLength(this.policy.MaxLength),  // Add MaxLength validation here
      Validators.pattern(this.buildPattern())
    ];
    this.changePwdForm.get('newPassword')?.setValidators(validators);
    this.changePwdForm.get('newPassword')?.updateValueAndValidity();


  }


  buildPattern(): string {
    let pattern = '^';

    if (this.policy.RequiresUpperCase) {
      pattern += '(?=.*[A-Z])';
    }
    if (this.policy.RequiresLowerCase) {
      pattern += '(?=.*[a-z])';
    }
    if (this.policy.RequiresNumber) {
      pattern += '(?=.*[0-9])';
    }
    if (this.policy.RequiresSpecialChar) {
      const specialChars = this.policy.SpecialCharacters.split('').map((c: string) => `\\${c}`).join('');
      pattern += `(?=.*[${specialChars}])`;
    }

    pattern += `.{${this.policy.MinLength
      },${this.policy.MaxLength}}$`;

    return pattern;
  }


  clickedInside = false;

  togglePasswordRequirements(): void {
    this.showPasswordRequirements = !this.showPasswordRequirements;
    this.clickedInside = true; // Set the flag to true
    setTimeout(() => this.clickedInside = false, 100); // Reset after a short delay
  }
  hasMinLength(password: string): boolean {
    return password.length >= this.policy.MinLength;
  }

  hasUppercaseLetter(password: string): boolean {
    return /[A-Z]/.test(password);
  }

  hasLowercaseLetter(password: string): boolean {
    return /[a-z]/.test(password);
  }

  hasSpecialCharacter(password: string): boolean {
    const specialCharsPattern = new RegExp(`[${this.policy.SpecialCharacters.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}]`);
    return specialCharsPattern.test(password);
  }

  hasNumber(password: string): boolean {
    return /[0-9]/.test(password);
  }




  ngOnInit() {
    this.getPwdRegExp()
    this.changePwdForm = this.formBuilder.group({
      oldPassword: [
        '',
        [
          // Validators.minLength(8),
          // Validators.maxLength(16),
        ],
      ],
      newPassword: [
        '',
        [
          Validators.required,
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
        ],
      ],
    });


    if(!this.hasEmptyPassword){
      this.changePwdForm
      .get('oldPassword')
      ?.setValidators([Validators.required]);
      this.changePwdForm.get('oldPassword')?.updateValueAndValidity();
    }
  }

  ngAfterViewInit() { }

  // convenience getter for easy access to form fields
  get f() {
    return this.changePwdForm.controls;
  }

  closeModal() {
    this.modalService.hide();
  }
  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleNewPwdFieldTextType() {
    this.newPwdfieldTextType = !this.newPwdfieldTextType;
  }
  toggleConfirmPwdFieldTextType() {
    this.oldPwdfieldTextType = !this.oldPwdfieldTextType;
  }

  /**
   * On submit form
   */
  updatePwd() {
    this.success = '';
    this.submitted = true;

    if (this.f['oldPassword'].value == this.f['newPassword'].value) {
      return;
    }
    // stop here if form is invalid
    console.log(this.changePwdForm)
    console.log(this.changePwdForm.invalid)
    if (
      this.changePwdForm.invalid &&
      this.f['confirmPassword'].value != this.f['newPassword'].value
    ) {
      return;
    }
    if (this.f['confirmPassword'].value != this.f['newPassword'].value) {
      return;
    }
    this.loaderService.setLoading(true);
    let payload: ChangePwdModal = {
      oldPassword: this.f['oldPassword'].value,
      newPassword: this.f['newPassword'].value,
      confirmPassword: this.f['confirmPassword'].value,
    };
    this.authService.updatePassword(payload).subscribe({
      next: (res) => {
        console.log(res);
        this.loaderService.setLoading(false);
        if (
          res.statusCode == 200 &&
          //res.message == 'Password reset successful.'
          res.message == "Password changed successfully."
        ) {
          this.otpSuccessMsg = true;
          this.otpErrorMsg = false;
          this.respMessage = res.message;
          setTimeout(() => {
            this.otpSuccessMsg = false;
            this.otpErrorMsg = false;
          }, 3000)

        } else {
          this.otpErrorMsg = true;
          this.otpSuccessMsg = false;
          this.respMessage = res.message;

          setTimeout(() => {
            this.otpSuccessMsg = false;
            this.otpErrorMsg = false;
          }, 3000)
          this.loaderService.setLoading(false);
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        this.otpErrorMsg = true;
        this.otpSuccessMsg = false;
        this.respMessage = err.error.message;
        console.log('err', err);
      },
    });
    // if (environment.defaultauth === 'firebase') {
    //   this.authenticationService.resetPassword(this.f.email.value)
    //     .catch(error => {
    //       this.error = error ? error : '';
    //     });
    // }
  }



  CreatePwd(){
    this.loaderService.setLoading(true)
    let payload = {
      password: this.f['newPassword'].value,
      confirmPassword: this.f['confirmPassword'].value,
      email: this.currentUserData?.email,
    };

    this.authService.newpassword(payload).subscribe({
      next: (res) => {
        // console.log(res);
        if (res.statusCode == 200) {
          this.loaderService.setLoading(false);
          // setTimeout(() => {
            this.closeModal()
          this.sharedService.showMessageDialog(
            'Success',
            res.message,
            SweetAlertIcon.SUCCESS
          );


          if (this.currentUserData?.hasEmptyPassword) {
            this.currentUserData.hasEmptyPassword = false; // Update the property
            localStorage.setItem('currentUser', JSON.stringify(this.currentUserData)); // Save updated data to localStorage
          }
      
        
          // }, 3000);
        } else {
          this.loaderService.setLoading(false);
          this.sharedService.showMessageDialog(
            'Failure',
            res.message,
            SweetAlertIcon.INFO
          );
        }
      },
      error: (err) => {
        this.sharedService.showMessageDialog(
          'Failure',
          err.error.message,
          SweetAlertIcon.INFO
        );
        this.loaderService.setLoading(false);
        //console.log('err', err);
      },
    });
  }


  newPassword() {
    this.success = '';
    this.newpasswordsubmitted = true;
  }
}
