import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RpUserProfileComponent } from './rp-user-profile/rp-user-profile.component';
import { SharedModule } from '../../shared/shared.module';
import { RpChangepasswordComponent } from './rp-changepassword/rp-changepassword.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
@NgModule({
  declarations: [RpUserProfileComponent, RpChangepasswordComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    UsersRoutingModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    SharedModule,
    LabelModule,
    DropDownsModule,
  ],
})
export class UsersModule {}
