import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DevicesState } from './iot-devices.reducer';

export const getdeviceState = createFeatureSelector<DevicesState>('device');

export const getdeviceResponse = createSelector(
  getdeviceState,
  (state: DevicesState) => state.getDevicesResponse
);

export const createdeviceResponse = createSelector(
  getdeviceState,
  (state: DevicesState) => state.createdeviceResponse
);

export const getAlldeviceResponse = createSelector(
  getdeviceState,
  (state: DevicesState) => state.getAlldeviceResponse
);

export const deletedeviceResponse = createSelector(
  getdeviceState,
  (state: DevicesState) => state.deletedeviceResponse
);
export const updatedeviceResponse = createSelector(
  getdeviceState,
  (state: DevicesState) => state.updatedeviceResponse
);
